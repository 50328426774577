import { graphql } from 'gatsby'
import React from 'react'
import { Layout, PageWithList } from '../components'
import PropTypes from 'prop-types'
import { getLayoutDataFromWebsiteData, getWebsiteData } from '../utils'

const Index = ({ location, data }) => {
	const cmsData = getWebsiteData(data)
	const pageData = cmsData.servicesPage
	const layoutData = getLayoutDataFromWebsiteData(cmsData)
	return (
		<Layout
			location={location}
			SeoProps={{ title: pageData.name, description: pageData.description }}
			{...layoutData}
		>
			<PageWithList {...pageData} listItems={pageData.services} orientation='vertical' />
		</Layout>
	)
}
Index.propTypes = {
	location: PropTypes.object,
	data: PropTypes.any,
}
export default Index
export const query = graphql`
	query q1 {
		allSanityWebsite(filter: { name: { eq: "Superba Website" } }) {
			edges {
				node {
					workingHours
					whatsappNumber
					socialsLinkedin
					socialsInstagram
					socialsFacebook
					phoneNumber
					waze
					email
					contactUsTitleShadow
					digitalCatalogueLocation
					contactUsTitle
					contactUsContent
					buttonContent
					address
					servicesPage {
						name
						description
						services {
							_key
							name
							content
							leftButtonTitle
							leftButtonRedirect
							rightButtonTitle
							rightButtonRedirect
							image {
								asset {
									gatsbyImageData(layout: CONSTRAINED)
								}
								_key
							}
						}
						title
						titleShadow
						content
					}
				}
			}
		}
	}
`
